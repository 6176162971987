import {Link} from "gatsby";
import React, {FC} from "react";
import Code from "../../components/code";
import Layout from "../../components/layout/layout-sidebar";
import Seo from "../../components/seo";
import {sidebarItemsGettingStarted} from "../../data/sidebar-items-getting-started";
import {apiDocsSlug} from "../../helpers/slug-helper";

const SelectorsPage: FC = () => (
    <Layout sidebarItems={sidebarItemsGettingStarted}>
        <Seo
            title="Getting started with walkers"
            description="Instructions for flexibly traversing cells in a grid in different ways."
        />
        <section className="p-8">
            <h1>{`Walkers`}</h1>
            <h2>{`What's a walker?`}</h2>
            <p
                dangerouslySetInnerHTML={{
                    __html: `In gridl a grid can be traversed with a so called walker. A walker is a <a href="https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Generator" target="_blank" rel="noopener noreferrer">generator function</a> that iterates from one cell to the next by calling the walker's <code>next()</code> method. `,
                }}
            />
            <Code code={codeExample1} language="javascript" />

            <h2>{`Traversing in a different order`}</h2>
            <p>
                {`The order in which the walker traverses the grid is determined by the specified `}
                <code>walk</code>
                {` function, which by default is `}
                <Link to={apiDocsSlug("walkWENS")}>walkWENS()</Link>
                {`. `}
                <b>WENS</b>
                {` stands for `}
                <b>West</b>
                {`, `}
                <b>East</b>
                {`, `}
                <b>North</b>
                {`, `}
                <b>South</b>
                {`, which means that the grid is traversed from West to East and from North to South. A `}
                <code>walk</code>
                {` function takes a cell index (positive integer between 0 and the number of cells minus 1) and calculates the associated position on the grid.`}
            </p>
            <Code code={codeExample2} language="javascript" />

            <h2>{`Functions that use walkers`}</h2>
            <p>
                {`Walkers are used by all iterating functions such as `}
                <Link to={apiDocsSlug("forEachCell")}>forEachCell</Link>
                {`, `}
                <Link to={apiDocsSlug("reduceGrid")}>reduceGrid</Link>
                {`, `}
                <Link to={apiDocsSlug("findCell")}>findCell</Link>
                {`, `}
                <Link to={apiDocsSlug("findPosition")}>findPosition</Link>
                {`, etc. This allows you to traverse the grid in a very flexible way.`}
            </p>
            <Code code={codeExample3} language="javascript" />
        </section>
    </Layout>
);

export default SelectorsPage;

const codeExample1 = `import {createWalker} from "gridl/core";

const grid = createGridFromArray2D([
    [1, 1, 1],
    [1, 1, 1],
]);
const walker = createWalker(grid);
walker.next(); // => {value: {index: 0, position: {x: 0, y: 0}}, done: false}
walker.next(); // => {value: {index: 1, position: {x: 1, y: 0}}, done: false}
walker.next(); // => {value: {index: 2, position: {x: 2, y: 0}}, done: false}
walker.next(); // => {value: {index: 3, position: {x: 0, y: 1}}, done: false}
walker.next(); // => {value: {index: 4, position: {x: 1, y: 1}}, done: false}
walker.next(); // => {value: {index: 5, position: {x: 2, y: 1}}, done: false}
walker.next(); // => {value: undefined, done: true}`;

const codeExample2 = `import {createWalker, walkSNWE} from "gridl/core";

const grid = createGridFromArray2D([
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [9, 10, 11]
]);
const walker = createWalker(grid, walkSNWE);

// traversing order using walkSNWE would be:
// [
//     [3, 7, 11],
//     [2, 6, 10],
//     [1, 5,  9],
//     [0, 4,  8],
// ]`;

const codeExample3 = `import {createWalker, walkSNWE} from "gridl/core";
import {forEachCell} from "gridl/sideEffects";

const grid = createGridFromArray2D([
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [9, 10, 11]
]);
const arr = [];

forEachCell(grid, (cellValue) => {
    arr.push(cellValue);
}, walkSNWE);

// => arr is: [9, 6, 3, 0, 10, 7, 4, 1, 11, 8, 5, 2]`;
